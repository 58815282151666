/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
let infosAreDisplayed = true;
let selectedTitle;
let previousMetric;

const buildQueryWithDate = (urlDate, locale) =>
  `{
    countries {
      iso2
      iso3
      countryTranslationsByIso2(condition: { lang: "${locale}" }) {
        translation
      }
      countryRegionByCountry {
        regionByRegion {
          region
          regionalValuesByRegion(condition: { day: "${urlDate}" }) {
            taxRate
            mrp
            regionalBondYields(maturityFilter: "10Y") {
              yield
            }
          }
        }
      }
      taxRatesByCountry(
        filter: { day: { lessThanOrEqualTo: "${urlDate}" } }
        orderBy: DAY_DESC
        first: 1
      ) {
        taxRate
      }
      bondYieldsByCountry(condition: { day: "${urlDate}", maturity: "10Y" }, first: 1) {
        yield
      }
      ratingsByCountry(
        filter: { day: { lessThanOrEqualTo: "${urlDate}" } }
        orderBy: [DAY_DESC, AGENCY_ASC]
        first: 1
      ) {
        rating
        agency
        day
        mrp(effectiveDate: "${urlDate}")
        ratingCreditSpread
        matureMarketErp(effectiveDate: "${urlDate}")
        emergingMarketsVolatilityAdjustment(effectiveDate: "${urlDate}")
      }
    }
  }`;

const buildAllYieldsByRatingQuery = (urlDate) =>
  `{
      allYieldsByRating(day: "${urlDate}"){
        ratingNumeric
        yieldBasedOnRating
      }
    }`;

const buildYieldsByRatingCalculationQuery = (urlDate) =>
  `{
      yieldsByRatingCalculation(day: "${urlDate}"){
        country
        rating
        agency
        ratingNumeric
        yield
        slope
        intercept
      }
    }`;

const isRating = (attribute) => attribute === 'rating';

const getGraphQueryByMetric = (metric, selectedCountry) => {
  switch (metric) {
    case 'riskFreeRate':
      if (selectedCountry.riskFreeRate == null) {
        // get region
        const regionElem = countryData.filter(
          (x) => x.name === selectedCountry.name,
        )[0];

        // eslint-disable-next-line prettier/prettier
        return `{regionalValues(condition: {region: "${regionElem.region.name}"}){
                  regionalBondYields(maturityFilter: "10Y"){
                    yield
                  }
                  day
                }}`;
      }

      // eslint-disable-next-line prettier/prettier
      return `{bondYields(condition: {country: "${selectedCountry.iso2}", maturity: "10Y"}) {
                yield
                day
              }}`;
    case 'corporateTaxRate':
      // eslint-disable-next-line prettier/prettier
      return `{taxRates(condition: {country: "${selectedCountry.iso2}"}) {
                taxRate
                day
              }}`;
    case 'mrp':
      // eslint-disable-next-line prettier/prettier
      return `{ratings(condition: {country: "${selectedCountry.iso2}", agency: "moodys"}) {
                mrp
                day
                agency
              }}`;
    case 'rating':
      // eslint-disable-next-line prettier/prettier
      return `{ratings(condition: {country: "${selectedCountry.iso2}", agency: "moodys"}) {
                rating
                day
                agency
              }}`;
    default:
      throw new Error(`Unknown metric ${metric}`);
  }
};

const metricToAccessor = (metric) => {
  switch (metric) {
    case 'riskFreeRate':
      return ['bondYield', 'yield'];
    case 'corporateTaxRate':
      return ['taxRate', 'tax_rate'];
    case 'mrp':
      return ['rating', 'mrp'];
    case 'rating':
      return ['rating', 'rating'];
    default:
      throw new Error(`Unknown metric ${metric}`);
  }
};

const percentFormat = (n) => (Number(n) === n ? d3.format('.1%')(n) : 'N/A');

const removeSelectedCountryStyle = (map) => {
  map
    .selectAll('path')
    .transition()
    .style('fill-opacity', 0.8)
    .style('stroke-opacity', 0.5)
    .style('stroke-width', 1)
    .duration(300);
};

const addSelectedCountryStyle = (path, duration) => {
  d3.select(path)
    .transition()
    .style('fill-opacity', 1)
    .style('stroke-opacity', 1)
    .style('stroke-width', 2)
    .duration(duration);
};

const setInfoboxValues = (country, region) => {
  d3.select('div.infobox h1.name').text(country.name);
  d3.select('div.infobox h2.region').text(region.name || 'N/A');

  if (percentFormat(country.riskFreeRate) === 'N/A') {
    if (percentFormat(region.yieldBasedOnRating) === 'N/A') {
      d3.select('div.infobox p.risk_free_rate')
        .text(`${percentFormat(region.riskFreeRate)} ⁱ`)
        .attr('title', `Regional median of ${region.name}`);
    } else {
      d3.select('div.infobox p.risk_free_rate')
        .text(`${percentFormat(region.yieldBasedOnRating)} ⁱ`)
        .attr('title', `Based on the country's rating`);
    }
  } else {
    d3.select('div.infobox p.risk_free_rate')
      .text(percentFormat(country.riskFreeRate))
      .attr('title', '');
  }

  if (percentFormat(country.corporateTaxRate) === 'N/A') {
    d3.select('div.infobox p.corporate_tax_rate')
      .text(`${percentFormat(region.corporateTaxRate)} ⁱ`)
      .attr('title', `Regional median of ${region.name}`);
  } else {
    d3.select('div.infobox p.corporate_tax_rate')
      .text(percentFormat(country.corporateTaxRate))
      .attr('title', '');
  }

  if (percentFormat(country.mrp) === 'N/A') {
    d3.select('div.infobox p.market_risk_premium')
      .text(`${percentFormat(region.mrp)} ⁱ`)
      .attr('title', `Regional median of ${region.name}`);
  } else {
    d3.select('div.infobox p.market_risk_premium')
      .text(percentFormat(country.mrp))
      .attr(
        'title',
        `Credit Spread based on Rating (${country.rating_credit_spread}) * Emerging Markets Volatility Adjustment (${country.emerging_markets_volatility_adjustment}) + Mature Market ERP (${country.mature_market_erp})`,
      );
  }

  document.querySelector('#rating .fa-chart-line').style.display =
    percentFormat(country.corporateTaxRate) === 'N/A' ? 'none' : 'block';

  document.querySelector('#corporateTaxRate .fa-chart-line').style.display =
    percentFormat(country.mrp) === 'N/A' ? 'none' : 'block';

  document.querySelector('#rating .fa-chart-line').style.display =
    country.rating === null || country.rating === undefined ? 'none' : 'block';

  d3.select('div.infobox p.rating').text(country.rating || 'N/A');
};

const showLoading = () => {
  const loadingElement = document.getElementById('loading');
  const containerElement = document.getElementById('container');
  loadingElement.style.display = 'flex';
  containerElement.style.visibility = 'collapse';
};

const hideLoading = () => {
  const loadingElement = document.getElementById('loading');
  const containerElement = document.getElementById('container');
  loadingElement.style.display = 'none';
  containerElement.style.visibility = 'visible';
};

const showGraphIcon = () => {
  d3.selectAll('.fa-chart-line').style('display', 'block');
};

const hideGraphIcon = () => {
  d3.selectAll('.fa-chart-line').style('display', 'none');
};

const displayGraph = (metric, title) => {
  if (!selectedCountry) {
    return;
  }

  selectedTitle = title;

  const query = getGraphQueryByMetric(metric, selectedCountry);

  if (metric === 'riskFreeRate' && selectedCountry.riskFreeRate == null) {
    d3.select('div.infobox h1.name').text(
      d3.select('div.infobox h2.region').text(),
    );

    drawGraph('allRegionalValues', query, 'regional_yield', title, urlDate);
  } else {
    drawGraph(
      queriesInfo[metric].method,
      query,
      queriesInfo[metric].attribut,
      title,
      urlDate,
    );
  }

  d3.selectAll('.infobox-data, .region').style('display', 'none');
  d3.select('#returnBtn').style('display', 'block');
  infosAreDisplayed = false;
};

const changeColorScale = (metric) => {
  if (!countryData) {
    return;
  }

  const accessor = metricToAccessor(metric);

  const scale = d3
    .scaleOrdinal()
    .range(d3.range(moodys.length, 0, -1))
    .domain(moodys);

  const getValue = (country) => country[accessor[0]][accessor[1]];

  const minValue = d3.min(countryData, (country) => {
    if (isRating(metric)) {
      return scale(getValue(country));
    }
    return getValue(country);
  });
  const maxValue = d3.max(countryData, (country) => {
    if (isRating(metric)) {
      return scale(getValue(country));
    }
    return getValue(country);
  });

  const colors = d3
    .scaleLinear()
    .domain([minValue, maxValue])
    .range(defaultColorScale);

  const colorMapping = countryData.reduce((map, obj) => {
    const value = getValue(obj);
    let valueReduce = null;
    let basedOnRating = false;
    let retMap;
    if (value) {
      valueReduce = isRating(metric) ? scale(value) : value;

      retMap =
        ((map[obj.iso3] = {
          color: valueReduce ? colors(valueReduce) : 'lightgrey',
          basedOnRating,
        }),
        map);
      return retMap;
    }

    if (metric === 'riskFreeRate' && 'based_on_rating' in obj.bondYield) {
      valueReduce = obj.bondYield.based_on_rating;
      basedOnRating = true;
    }

    retMap =
      ((map[obj.iso3] = {
        color: valueReduce ? colors(valueReduce) : 'lightgrey',
        basedOnRating,
      }),
      map);
    return retMap;
  }, {});

  map
    .selectAll('path')
    .transition()
    .style('fill', (d) => {
      if (colorMapping[d.id]) {
        return colorMapping[d.id].color;
      }
      return 'lightgrey';
    })
    .style('mask', (d) => {
      if (colorMapping[d.id]) {
        if (colorMapping[d.id].basedOnRating) {
          return 'url(#ratingYieldMask)';
        }
      }
    })
    .duration(1000);
};

const changeMetric = (metric) => {
  previousMetric = selectedMetric;
  selectedMetric = metric;

  d3.selectAll('h3').style('font-weight', '200');

  d3.selectAll('.infobox-data-cell').style('background-color', 'inherit');
  d3.select(`div#${metric}`).style(
    'background-color',
    'rgba(67, 116, 177, 0.7)',
  );
  d3.select(`div#${metric} h3`).style('font-weight', 'bold');

  changeColorScale(metric);
};

const displayInfos = () => {
  if (isRating(selectedMetric)) {
    changeMetric(previousMetric);
  }

  d3.select('.infobox svg').remove();
  d3.select('#returnBtn').style('display', 'none');
  d3.selectAll('.infobox-data, .region').style('display', 'flex');
  infosAreDisplayed = true;
};

const changeCountry = (country) => {
  let region = {};

  if (country && country.region) {
    region = {
      name: country.region.name,
      riskFreeRate: country.bondYield.regional_yield,
      corporateTaxRate: country.taxRate.regional_tax_rate,
      mrp: country.rating.regional_mrp,
      yieldBasedOnRating: country.bondYield.based_on_rating,
    };

    country = {
      name: country.name,
      iso2: country.iso2,
      iso3: country.iso3,
      riskFreeRate: country.bondYield.yield,
      corporateTaxRate: country.taxRate.tax_rate,
      mrp: country.rating.mrp,
      rating: country.rating.rating,
      rating_credit_spread: country.rating.rating_credit_spread,
      emerging_markets_volatility_adjustment:
        country.rating.emerging_markets_volatility_adjustment,
      mature_market_erp: country.rating.mature_market_erp,
    };
  }

  selectedCountry = country;
  showGraphIcon();
  setInfoboxValues(country, region);
};

const drawMap = (geography) => {
  const width = chartDiv.clientWidth;
  const height = chartDiv.clientHeight;

  const factor = 16 / 7;
  const calculatedWidth = height * factor;

  svg
    .attr('viewBox', `0 0 ${calculatedWidth} ${height}`)
    .attr('preserveAspectRatio', 'xMidYMin meet');

  const scaleFactor = height / Math.PI;

  const projection = d3
    .geoRobinson()
    .scale(scaleFactor)
    .rotate([352, 0, 0])
    .translate([calculatedWidth / 2, height / 2 + 50]);

  const path = d3.geoPath().projection(projection);
  paths = map
    .selectAll('path')
    .data(geography.features)
    .enter()
    .append('path')
    .attr('d', path)
    .style('fill-opacity', 0.8)
    .style('stroke', 'white')
    .style('stroke-width', 1)
    .style('stroke-opacity', 0.5)
    .style('fill', 'lightgrey')
    // tooltips
    .on('click', (d, i, nodes) => {
      const country = countryData.find((c) => c.iso3 === d.id);
      changeCountry(country);
      if (!infosAreDisplayed) {
        displayGraph(selectedMetric, selectedTitle);
      }

      removeSelectedCountryStyle(map);
      addSelectedCountryStyle(nodes[i], 300);

      d3.event.stopPropagation();
    });
};

const showWorldData = () => {
  const riskFreeRate = d3.median(countryData.map((d) => d.bondYield.yield));
  const corporateTaxRate = d3.median(
    countryData.map((d) => d.taxRate.tax_rate),
  );
  const marketRiskPremium = d3.median(countryData.map((d) => d.rating.mrp));

  const country = {
    name: I18n.t('resources.world'),
    riskFreeRate,
    corporateTaxRate,
    mrp: marketRiskPremium,
  };

  hideGraphIcon();
  setInfoboxValues(country, {});
};

const dateToString = (day) => {
  if (!(day instanceof Date)) return day;

  const dd = String(day.getDate()).padStart(2, '0');
  const mm = String(day.getMonth() + 1).padStart(2, '0');
  const yyyy = day.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};
